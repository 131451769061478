import { useEffect, useState } from 'react';
import './styles.css';

export const Alert = () => {
  const [showAlert, setShowAlert] = useState(false);

  const handleClick = () => {
    setShowAlert(false);

    localStorage.setItem('InfoTurboTelecom@AcceptCookies', true);
  };

  useEffect(() => {
    const showAlert = localStorage.getItem('InfoTurboTelecom@AcceptCookies') ?? false;

    setShowAlert(!showAlert);
  }, []);

  return showAlert ? (
    <div className="alert-styled alert alert-secondary alert-dismissible" role="alert">
      Este site utiliza cookies para garantir uma experiência de usuário aprimorada e personalizada. Ao continuar a navegar neste site, você concorda com o uso de cookies de acordo com nossa Política de Privacidade.
      <button onClick={handleClick} className="btn btn-primary">
        OK
      </button>
    </div>
  ) : <></>;
};
