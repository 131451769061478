import React from 'react';

export function Header({ config, city }) {
  return (
    <header
      className="masthead"
      style={{
        backgroundImage: config?.background || city?.background ? `linear-gradient(to bottom, rgba(92, 77, 66, 0.8) 0%, rgba(92, 77, 66, 0.8) 100%), url(${ process.env.REACT_APP_API_URL }/image/${ city ? city?.background : config?.background })` : 'none',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundClip: 'border-box',
      }}
    >
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center text-center">
          <div className="col-lg-10 align-self-end">
            <h1 className="text-uppercase text-white font-weight-bold">Quer ter uma internet de qualidade?</h1>
            <hr className="divider my-4" />
          </div>
          <div className="col-lg-8 align-self-baseline">
            <p className="text-white-75 font-weight-light mb-5">
              Venha para a InfoTurbo Telecom.
              <br/>
              Internet para toda família!
            </p>

            <a className="btn btn-primary btn-xl js-scroll-trigger" href="#about">Descubra Mais</a>
          </div>
        </div>
      </div>
    </header>
  );
};
