import React from 'react';

import {
  CheckCircle,
  XCircle,
} from '@phosphor-icons/react';

import './styles.css';

export function Services({ plansByCity, benefits, servicesIncluded, setSelectedPlan }) {
  return (
    <section className="page-section" id="plans">
      <div className="container">
        <h2 className="text-center mt-0">Nossos planos</h2>
        <hr className="divider my-4" />

        <br />
        <h5 className="text-center">Veja quais planos estão disponíveis na sua cidade</h5>
        <br />
        <br />

        <div className="services">
          {
            plansByCity && plansByCity.length > 0
              ? plansByCity
                  .sort(( firstItem, secondItem ) => Number( firstItem.value.replace(/[^\d]/g, '')) - Number( secondItem.value.replace(/[^\d]/g, '')))
                  .map( plan =>
                    <div
                      key={ plan._id }
                      className={`service-card${plan.bestSeller ? ' service-card-best-seller' : ''}`}
                    >
                      <div className="service-card-row">
                        <div className="service-card-info">
                          <div className="service-velocity-view">
                            <span className="service-velocity">{ plan.name.replace(/[^\d]/g, '')}</span>
                            <span className="service-velocity-category">{ plan.name.replace(/[\d]/g, '')}</span>
                          </div>

                          <span className="service-type">{ plan.type }</span>
                          <span className="service-value"><span>R$</span>{ plan.value.replace('R$', '')}<span>/mês</span></span>

                          <span className="service-best-seller">{plan.bestSeller ? 'Mais Vendido' : ''}</span>

                          {
                            plan.description && <p className="service-description">{ plan.description }</p>
                          }

                          <div className="service-benefits-view">
                            {
                              benefits.filter( benefit => {
                                if( plan.type === 'Fibra Óptica')
                                  return benefit.fiber;
                                else
                                  return benefit.radio;
                              }).map(( benefit ) =>
                                <div
                                  className="service-benefit-view"
                                  key={ benefit._id }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title={ benefit.description ?? benefit.name }
                                >
                                  {
                                    plan.benefits.map( _benefit => _benefit._id ).includes( benefit._id )
                                      ? <CheckCircle
                                          size={ 24 }
                                          // weight="bold"
                                        />
                                      : <XCircle
                                          size={ 24 }
                                          // weight="bold"
                                        />
                                  }

                                  <span key={ benefit._id } className="service-benefit-name">{ benefit.name }</span>
                                </div>
                              )
                            }
                          </div>
                        </div>

                        {
                          plan.servicesIncluded.length > 0 && (
                            <div className="includes-view">
                              <span>Serviços Inclusos</span>
                              {
                                servicesIncluded.slice(0, 7).filter((service) => plan.servicesIncluded.some((s) => s._id === service._id)).map((service) => (
                                  <img
                                    key={service._id}
                                    src={`${process.env.REACT_APP_API_URL }/image/${ service.logo }`}
                                    data-toggle="tooltip" data-placement="right" title={service.name}
                                  />
                                ))
                              }
                            </div>
                          )
                        }
                      </div>

                      <button
                        className="btn service-button"
                        onClick={() => setSelectedPlan( plan._id )}
                      >
                        Assinar
                      </button>
                    </div>
                  )
              : <div className="container mt-5">
                  <h5 className="text-center">Nenhum plano para essa cidade!</h5>
                </div>
          }
        </div>
      </div>
    </section>
  );
};
