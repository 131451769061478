import React from 'react';

export function Contact({ city }) {
  return (
    <section className="page-section" id="contact">
      {
        city &&
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="mt-0">Contato</h2>
                <hr className="divider my-4" />
                <p className="text-muted mb-5">Caso tenha duvidas entre em contato conosco nos canais abaixo!</p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                <a target="_blank" rel="noreferrer" className="d-block" href={`https://wa.me/55${ city?.whatsapp.replace(/\D/g, "")}`}>
                  <i className="fab fa-whatsapp fa-3x mb-3 text-muted"></i>
                </a>

                <a target="_blank" rel="noreferrer" className="d-block" href={`https://wa.me/55${ city?.whatsapp.replace(/\D/g, "")}`}>{ city?.whatsapp }</a>
              </div>

              <div className="col-lg-4 ml-auto text-center mb-5 mb-lg-0">
                <i className="fas fa-phone fa-3x mb-3 text-muted"></i>
                <div>{ city?.phone }</div>
              </div>

              <div className="col-lg-4 mr-auto text-center">
                <a className="d-block" href="mailto:contato@infoturbo.com.br">
                  <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
                </a>

                <a className="d-block" href="mailto:contato@infoturbo.com.br">{ city?.email }</a>
              </div>
            </div>
          </div>
      }
    </section>
  );
};
