import React, {
  useEffect,
  useState,
} from 'react';

import api from '../../services/api';

import {
  Navbar,
  Header,
  Section,
  Services,
  Posts,
  Contact,
  Footer,
  ModalCities,
} from '../../components';
import { ModalInstallation } from '../../components/ModalInstallation';
import { Alert } from '../../components/Alert';
import { hashIdGeneretor } from '../../utils/hashId';

export function Main() {
  const [ config, setConfig ] = useState( null );

  const [ cities, setCities ] = useState([]);
  const [ benefits, setBenefits ] = useState([]);
  const [ servicesIncluded, setServicesIncluded ] = useState([]);
  const [ plans, setPlans ] = useState([]);
  const [ plansByCity, setPlansByCity ] = useState([]);
  const [ selectedCity, setSelectedCity ] = useState( null );
  const [ selectedPlan, setSelectedPlan ] = useState( null );

  async function loadConfig() {
    const { data } = await api.get('/site/config');

    const {
      success,
      result,
    } = data;

    if( success ) {
      setConfig( result );
    }
  };

  async function loadCities() {
    const { data } = await api.get('/site/cities');

    const {
      success,
      result,
    } = data;

    if( success ) {
      setCities( result );
    }
  };

  async function registerVisit() {
    let hashId = localStorage.getItem('InfoTurboTelecom@HashId');

    if(!hashId) {
      hashId = hashIdGeneretor();

      localStorage.setItem('InfoTurboTelecom@HashId', hashId);
    }

    const { data: { ip }} = await api.get('https://api.ipify.org/?format=json');

    await api.post('/site/visit', {
      hashId,
      ipAddress: ip,
    });
  };

  async function loadBenefits() {
    const { data } = await api.get('/site/benefits');

    const {
      success,
      result,
    } = data;

    if( success ) {
      setBenefits( result );
    }
  };

  async function loadServices() {
    const { data } = await api.get('/site/services');

    const {
      success,
      result,
    } = data;

    if( success ) {
      setServicesIncluded( result );
    }
  };

  async function loadPlans() {
    const { data } = await api.get('/site/plans');

    const {
      success,
      result,
    } = data;

    if( success ) {
      setPlans( result );
    }
  };

  useEffect(() => {
    if( selectedCity && plans )
      setPlansByCity( plans.filter( plan => plan.cities.some( city => city._id === selectedCity._id )));
  }, [ selectedCity, plans ]);

  useEffect(() => {
    loadCities();
    loadBenefits();
    loadServices();
    loadPlans();
    loadConfig();

    registerVisit();

    const city = localStorage.getItem('InfoTurboTelecom@SelectedCity');

    if( city )
      setSelectedCity( JSON.parse( city ));
  }, []);

  return (
    <>
      <Navbar
        selectedCity={ selectedCity }
      />

      <Header
        config={ config }
        city={ selectedCity }
      />

      <Section />

      <Services
        plansByCity={ plansByCity }
        benefits={ benefits }
        servicesIncluded={ servicesIncluded }
        setSelectedPlan={( id ) => setSelectedPlan( id )}
      />

      <Posts />

      <Contact
        city={ selectedCity }
      />

      <Footer />

      <ModalCities
        cities={ cities }
        selectedCity={ selectedCity }
        setSelectedCity={ setSelectedCity }
      />

      <ModalInstallation
        plan={ selectedPlan }
        cleanSelectedPlan={() => setSelectedPlan(null)}
      />

      <Alert />
    </>
  );
};
