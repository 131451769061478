import React, { useEffect, useState } from 'react';

import api from '../../services/api';

export function Posts() {
  const [ posts, setPosts ] = useState([]);

  async function loadPosts() {
    const { data } = await api.get('/site/posts');

    const {
      success,
      result,
    } = data;

    if( success ) {
      setPosts( result );
    }
  };

  useEffect(() => {
    loadPosts();
  }, []);

  return (
    <section className="page-section" id="posts">
      <div className="container">
        <h2 className="text-center mt-0">Ultimas instalações</h2>
        <hr className="divider my-4" />
        <div id="portfolio">
          <div className="container-fluid p-0">
            <div className="row no-gutters">
              {
                posts.length > 0
                  ? posts.map( post =>
                      <div key={ post._id } className="col-lg-4 col-sm-6">
                        <span className="portfolio-box">
                          <img className="img-fluid" src={`${ process.env.REACT_APP_API_URL }/image/${ post?.file }`} alt="" />
                          <div className="portfolio-box-caption">
                            <div className="project-name">{ post.name }</div>
                          </div>
                        </span>
                      </div>
                    )
                  : <div className="container mt-5">
                      <h5 className="text-center">Nenhuma postagem!</h5>
                    </div>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
