import React from 'react';

export function ModalCities({ cities, selectedCity, setSelectedCity }) {

  function handleSelectCity( city ) {
    setSelectedCity( city );

    localStorage.setItem('InfoTurboTelecom@SelectedCity', JSON.stringify( city ));
  };

  return (
    <div className="modal fade" data-backdrop={ !selectedCity ? 'static' : true } id="modalCities" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Selecionar Uma Cidade</h5>
          </div>
          <div
            className="modal-body center"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div className="dropdown">
              <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                { selectedCity?._id ? `${ selectedCity?.name } - ${ selectedCity?.state }` : 'Selecionar Cidade'}
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {
                  cities && cities.map( city =>
                    <span
                      key={ city._id }
                      className="dropdown-item"
                      onClick={() => handleSelectCity( city )}
                      data-dismiss="modal"
                    >
                      { city.name } - { city.state }
                    </span>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
