import React from 'react';

export function Section() {
  return (
    <section className="page-section bg-primary" id="about">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8 text-center">
            <h2 className="text-white mt-0">Venha ser InfoTurbo Telecom</h2>
            <hr className="divider light my-4" />
            <p className="text-white-50 mb-4">Trabalhamos com o melhor serviço de destribuição de internet por fibra da região</p>
            <p className="text-white-50 mb-4">Com os melhores planos, que cabe no seu bolso</p>
            <a className="btn btn-secondary btn-xl js-scroll-trigger" href="#plans">Nossos planos</a>
          </div>
        </div>
      </div>
    </section>
  );
};
