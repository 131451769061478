import React from 'react';

import logo from '../../assets/logo.png';

export function Navbar({ selectedCity }) {
  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top py-3" id="mainNav">
      <div className="container">
        <a className="navbar-brand js-scroll-trigger" href="#page-top">
          <img className="navbar-logo" src={ logo } alt="InfoTurbo Telecom"/>
        </a>
        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto my-2 my-lg-0">
            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#about">Sobre</a></li>
            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#plans">Planos</a></li>
            <li className="nav-item"><a className="nav-link js-scroll-trigger" href="#contact">Contato</a></li>

            <li className="nav-item"><span className="btn btn-secondary btn-sm js-scroll-trigger" data-toggle="modal" data-target="#modalCities">{ selectedCity?._id ? `${ selectedCity?.name } - ${ selectedCity?.state }` : 'Selecionar Cidade'}</span></li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
