import React, { useCallback, useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';
import './styles.css';

import api from '../../services/api';

export function ModalInstallation({ plan, cleanSelectedPlan }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');

  const [showModal, setShowModal] = useState(false);

  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const onSubmit = useCallback(async () => {
    try {
      const { data: {message} } = await api.post('/installation', {
        name,
        email,
        whatsapp,
        plan_id: plan,
      });

      setSuccess( message );

      setTimeout(() => {
        cleanSelectedPlan();
      }, 3000);
    } catch (error) {
      setError(error.response.data.message);
    }
  }, [ name, email, whatsapp, plan ]);

  let props = {};

  useEffect(() => {
    setShowModal(!!plan);

    if(plan) {
      delete props['aria-hidden'];

      props['aria-modal'] = true;
      props['role'] = 'dialog';
    } else {
      delete props['aria-modal'];
      delete props['role'];

      props['aria-hidden'] = true;

      setName('');
      setWhatsapp('');
      setEmail('');

      setSuccess(null);
      setError(null);
    }
  }, [plan]);

  return showModal ? (
    <div
      className={`modal fade${showModal ? ' show' : ''}`}
      id="modalInstallation"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      {...props}
      style={{
        display: showModal ? 'block' : 'none',
        paddingRight: 0,
        backgroundColor: '#0007'
      }}
    >
      <div className="modal-dialog modal-dialog-centered modal-sm" style={{ maxWidth: 400 }}>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Solicitar Instalação</h5>
          </div>
          <div
            className="modal-body center"
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">Nome*</span>
              </div>
              <input
                type="text"
                className="form-control"
                aria-label="Nome"
                value={ name }
                onChange={( event ) => setName( event.target.value )}
              />
            </div>

            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">Whatsapp*</span>
              </div>

              <ReactInputMask
                mask="(99) 9 9999-9999"
                value={ whatsapp }
                onChange={(event) => setWhatsapp( event.target.value )}
              >
                {() => <input type="cel-phone" className="form-control" />}
              </ReactInputMask>
            </div>

            <div className="input-group input-group-sm mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-sm">Email</span>
              </div>
              <input
                type="text"
                className="form-control"
                aria-label="Email"
                value={ email }
                onChange={( event ) => setEmail( event.target.value )}
              />
            </div>

            <button
              className="btn service-button"
              onClick={ onSubmit }
              disabled={
                !name ||
                !whatsapp
              }
            >
              Enviar
            </button>

            <button
              className="btn cancel-button"
              onClick={cleanSelectedPlan}
            >
              Cancelar
            </button>

            {
              error &&
              <div className="alert alert-danger mt-3" role="alert">
                { error }
              </div>
            }

            {
              success &&
              <div className="alert alert-success mt-3" role="alert">
                { success }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  ) : <></>;
};
