import React from 'react';

import {
  Main,
} from './Pages';

export default function App() {
  return (
    <Main />
  );
}
